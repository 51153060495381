<template>
  <div>
    <form
      class="form"
      v-if="!submitted"
      @submit.prevent="submit"
    >
      <div class="content">
        <div class="columns is-mobile">
          <div class="column">
            <p class="title is-4 has-text-primary">
              Request a callback
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
        </div>
        <p>{{ enquiryMessage }}</p>
      </div>
      <div class="field">
        <label class="label">First name</label>
        <input
          name="firstname"
          v-model="enquiry.firstname"
          v-validate="'required'"
          data-vv-as="first name"
          type="text"
          class="input"
        />
        <span class="help has-text-danger" v-if="errors.has('firstname')">
          {{ errors.first('firstname') }}
        </span>
      </div>
      <div class="field">
        <label class="label">Last name</label>
        <input
          name="lastname"
          v-model="enquiry.lastname"
          v-validate="'required'"
          data-vv-as="last name"
          type="text"
          class="input"
        />
        <span class="help has-text-danger" v-if="errors.has('lastname')">
          {{ errors.first('lastname') }}
        </span>
      </div>
      <div class="field">
        <label class="label">Job title</label>
        <input
          name="jobtitle"
          v-model="enquiry.jobtitle"
          type="text"
          class="input"
        />
      </div>
      <div class="field">
        <label class="label">Contact email</label>
        <input
          name="email"
          v-model="enquiry.email"
          v-validate="'required|email'"
          type="text"
          class="input"
        />
        <span class="help has-text-danger" v-if="errors.has('email')">
          {{ errors.first('email') }}
        </span>
      </div>
      <div class="field">
        <label class="label">Contact telephone</label>
        <input
          name="phone"
          v-model="enquiry.phone"
          v-validate="'required'"
          data-vv-as="contact telephone"
          type="text"
          class="input"
        />
        <span class="help has-text-danger" v-if="errors.has('phone')">
          {{ errors.first('phone') }}
        </span>
      </div>
      <div class="content">
        <label class="label">Sector/Industry</label>
        <span class="select is-fullwidth">
          <select
            name="industry"
            v-model="enquiry.industry"
            v-validate="'required'"
          >
            <option value selected disabled>Choose from options</option>
            <option value="Auctions & remarketing">
              Auctions &amp; remarketing
            </option>
            <option value="Dealerships & traders">
              Dealerships &amp; traders
            </option>
            <option value="Lenders & brokers">Lenders &amp; brokers</option>
            <option value="Fleet, leasing and rental">
              Fleet, leasing and rental
            </option>
            <option value="Vehicle manufacturers">Vehicle manufacturers</option>
            <option value="Hedge fund & financial institutions">
              Hedge fund &amp; financial institutions
            </option>
          </select>
          <span class="help has-text-danger" v-if="errors.has('industry')">
            {{ errors.first('industry') }}
          </span>
        </span>
      </div>
      <div class="has-text-right">
        <button type="submit" class="button is-soft is-rounded">Send</button>
      </div>
    </form>
    <div
      v-if="submitted"
      class="is-flex is-flex-direction-row-reverse is-align-content-center is-justify-content-space-between is-flex-wrap-inherit is-align-items-center"
    >
      <div>
        <button
          class="button is-soft is-rounded"
          @click="closeModal"
        >
          Close
        </button>
      </div>
      <div>
        <p class="title is-4 has-text-success">
          Thank you!
        </p>
        <p class="subtitle is-6">
          Your message has been received and we'll be in touch
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { node } from '@/plugins/axios'
export default {
  name: 'BusinessEnquiry',
  data: () => ({
    enquiry: {
      firstname: '',
      lastname: '',
      jobtitle: '',
      email: '',
      phone: '',
      industry: '',
      enquirytype: ''
    },
    submitted: false
  }),
  props: {
    enquiryMessage: {
      type: String,
      required: false,
      default: "Drop us a message and we'll get back to you as soon as possible"
    },
    enquiryType: {
      type: String,
      required: false,
      default: 'sales enquiry'
    }
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validate()
      if (!valid) return
      try {
        this.enquiry.enquirytype = this.enquiryType
        const { enquiry } = this
        await node.post('/misc/enquiry', { enquiry })
        this.submitted = true
      } catch (err) {
        this.$notify('Something went wrong')
      }
    },
    closeModal() {
      this.$modal.close()
    }
  },
}
</script>
